<template>
    <v-container fluid style="padding-bottom:80px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row class="mt-5 ml-2 mr-2">
        <v-col cols="12">
            <v-card>
                <v-card-title class="p-3" style="background-color: #d7e0e8;">
                    <v-row>
                        <v-col cols="12">
                            <v-icon class="mr-5">mdi-email</v-icon> MPF Creation Form
                        </v-col>
                        <v-col cols="12">
                            <p style="font-size:1rem">
                                Created By : {{this.$store.state.user.username}}
                            </p>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-3 mb-3">
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-layers"
                                    clearable
                                    v-model="category"
                                    :items="categories"
                                    item-value="cat_id"
                                    item-text="cat_desc"
                                    label="Subject"
                                    @change="(event) => getOrderID(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" v-show="type_show">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-layers"
                                    clearable
                                    v-model="type"
                                    :items="types"
                                    item-value="value"
                                    item-text="label"
                                    label="Type"
                                    @change="(event) => getExtend(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" v-show="extend_show">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-layers"
                                    clearable
                                    v-model="extend"
                                    :items="extendes"
                                    item-value="extend_id"
                                    :item-text="item => item.descr.trim() + ' | ' + item.day + ' Hari'"
                                    label="Extend Type"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    prepend-inner-icon="mdi-watermark"
                                    label="Receiver"
                                    persistent-hint
                                    required
                                    v-model="receiver"
                                    disabled
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    prepend-inner-icon="mdi-format-line-weight"
                                    label="SC Number"
                                    persistent-hint
                                    required
                                    v-model="sc_number"
                                >
                                    <template slot="append-outer">
                                        <v-btn class="rounded-l text-white p-3 text-right" color="primary" style="margin-top: -5px;" @click="showModal()">
                                            Check
                                            <v-icon>
                                                mdi-check
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    prepend-inner-icon="mdi-pencil"
                                    label="Remark"
                                    persistent-hint
                                    required
                                    v-model="remark"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <input class="mt-8" type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn block class="rounded-l" color="primary" @click="submit()">Submit</v-btn>
                </v-card-actions>
            </v-card>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-dialog
                v-model="dialog"
                persistent
                max-width="500px"
            >
                <v-card class="p-3 rounded-xl">
                    <v-card-title class="text-center">
                        <v-row align="center" justify="center">
                            <v-col cols="10">
                            <h5 style="font-weight:700; color: green;">
                                Detail of {{sc_number}}
                            </h5>
                            </v-col>
                        </v-row>  
                    </v-card-title>
                    <v-card-text>
                        <v-container fluid class="fill-height">
                            <v-row align="center" justify="center">
                                <v-col cols="12" xs="12" sm="2" md="2">
                                    <v-icon x-large color="success" class="text-center">
                                        mdi-clock-outline
                                    </v-icon>
                                </v-col>
                            </v-row> 
                            <v-row class="mt-2" align="center" justify="center">
                                <v-col cols="12" xs="12" sm="12" md="12">
                                    <h6 class="text-center">
                                        Salesman : {{order ? order.salesman_name : ''}}
                                    </h6>
                                    <h6 class="text-center">
                                        Customer : {{order ? order.cust_name : ''}}
                                    </h6>
                                    <h6 class="text-center" style="color: #053d76; font-weight: 600">
                                        <b>Sc. Date : {{order ? order.dt_order : ''}}</b>
                                    </h6>
                                    <p class="text-center mb-0">Status : {{order ? order.stat : ''}}</p>
                                    <p class="text-center mt-0">Extend Day : {{order ? order.day_change : ''}}</p>
                                </v-col>
                            </v-row> 
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="text-center">
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" @click="dialog = false">Oke</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'MPF',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'Create',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            categories: [],
            category: '',
            receiver: '',
            sc_number: '',
            remark: '',
            file: '',
            type_show: false,
            types: [
                {
                    label: 'SC (Sales Contract)',
                    value: 'SC'
                },
                {
                    label: 'PPP (P3)',
                    value: 'PP'
                }
            ],
            type: '',
            extendes: [

            ],
            extend: '',
            extend_show: false,
            order: null,
            dialog: false
        }
    },
    mounted(){
        this.getCategoryMPF()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        async getCategoryMPF(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/category_mpf`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.categories = res.data
            });
        },   
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
        },  
        submit(){
            this.$store.dispatch('setOverlay', true)

            if (this.category === '' || this.sc_number === '' || this.extend === '') {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Fill Data',
                    visible: true
                };

                return false
            }

            let formData = new FormData();
            formData.append('txtCategory', this.category);
            formData.append('txtOrderId', this.sc_number);
            formData.append('txtRemark', this.remark ? this.remark : '');
            formData.append('txtType', this.type ? this.type : '');
            formData.append('txtExtendType', this.extend ? this.extend : '');
            formData.append('txtAttachment', this.file ? this.file : '');

            axios.post(`${process.env.VUE_APP_URL}/api/sr/mpf/store`, formData , 
            { 
              headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
            }).then(res => {
                this.$store.dispatch('setOverlay', false)

                this.category = ''
                this.sc_number = ''
                this.remark = ''
                this.type = ''
                this.extend = ''
                $("#file").val('');
                this.type_show = false
                this.extend_show = false

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Save Data",
                    visible: true
                };
            }).catch(err => {
                // this.loading_item_tenant = false
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Error',
                    visible: true
                };
            })
        },
        async showModal(){
            console.log(this.sc_number);

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/mpf/checkOrder?type_id=${this.type}&id=${this.sc_number}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                if (res.data.length > 0) {
                    this.order = res.data[0]
                    this.dialog = true
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: 'Data not found, please check again',
                        visible: true
                    };
                    return false;
                }
            });
        },
        async getOrderID(value){
            if (value === '01') {
                this.type_show = true
                this.extend_show = false
            } else {
                this.type_show = false
                this.extend_show = false
            }

            this.type = ''

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/mpf/needOrderID?cat_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.receiver = res.data.receiver.descr
                console.log(res.data);
            });
        },
        async getExtend(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/mpf/extend?type_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.extend_show = true
                console.log(res.data);
                this.extendes = res.data
            });
        }
    },
    watch: {
    }
}
</script>
  